exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dla-inwestora-js": () => import("./../../../src/pages/dla-inwestora.js" /* webpackChunkName: "component---src-pages-dla-inwestora-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-kontakt-potwierdzenie-js": () => import("./../../../src/pages/kontakt-potwierdzenie.js" /* webpackChunkName: "component---src-pages-kontakt-potwierdzenie-js" */),
  "component---src-pages-lokalizacja-js": () => import("./../../../src/pages/lokalizacja.js" /* webpackChunkName: "component---src-pages-lokalizacja-js" */),
  "component---src-pages-mieszkania-js": () => import("./../../../src/pages/mieszkania.js" /* webpackChunkName: "component---src-pages-mieszkania-js" */),
  "component---src-pages-o-deweloperze-js": () => import("./../../../src/pages/o-deweloperze.js" /* webpackChunkName: "component---src-pages-o-deweloperze-js" */),
  "component---src-pages-o-inwestycji-js": () => import("./../../../src/pages/o-inwestycji.js" /* webpackChunkName: "component---src-pages-o-inwestycji-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-strefa-klienta-js": () => import("./../../../src/pages/strefa-klienta.js" /* webpackChunkName: "component---src-pages-strefa-klienta-js" */),
  "component---src-templates-apartment-js": () => import("./../../../src/templates/apartment.js" /* webpackChunkName: "component---src-templates-apartment-js" */)
}

